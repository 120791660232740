// import { Basics } from '../_app/cuchillo/core/Basics';
// import { GetBy } from '../_app/cuchillo/core/Element';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import Wrap from '../layout/Wrap';

export default class Track extends Page {
  // _download;
  // _call = {
  //   click: () => this.click()
  // }

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor() {
    super();

    // this._download = GetBy.id('downloadBtn');
    // this._download.addEventListener(Basics.clickEvent, this._call.click);
  }

  // click() {
  //   const a = document.createElement('a');
  //   const url = this._download.dataset.src
  //   a.href = url;
  //   a.target = '_blank';
  //   a.download = url.split('/').pop();
  //   document.body.appendChild(a);
  //   a.click();
  //   console.log(a);
  //   document.body.removeChild(a);
  // }

  show__effect(__call) {
    Wrap.show(() => { this.afterShow() });
  }

  hide__effect() {
    Wrap.hide(() => { this.afterHide(); });
  }

  // dispose() {
  //   this._download.removeEventListener(Basics.clickEvent, this._call.click);
  //   super.dispose();
  // }
}

ControllerPage._addPage('track', Track)
