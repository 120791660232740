import gsap, { Power2 } from 'gsap';
import { Basics, isMobile } from '../_app/cuchillo/core/Basics';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import MusicController from '../controllers/MusicController';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Metrics } from '../_app/cuchillo/core/Metrics';

class ScrollItem__WidgetTracks extends VScroll_Item {
  _filters;
  _tracks;
  _images;

  _calls = {
    click: (e) => this.filter(e),
    hoverIn: (e) => this.hoverIn(e),
    hoverOut: () => this.hoverOut()
  }

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._filters = GetBy.class('__filter', __link);
    this._tracks = GetBy.class('__track', __link);
    this._images = GetBy.selector('#ImagesContainer .__image');

    for (let i = 0; i < this._filters.length; i++) {
      this._filters[i].addEventListener(Basics.clickEvent, this._calls.click);
    }

    if (!isMobile) {
      for (let i = 0; i < this._tracks.length; i++) {
        const track = this._tracks[i];
        track.addEventListener(Basics.mouseOver, this._calls.hoverIn);
        track.addEventListener(Basics.mouseOut, this._calls.hoverOut);
      }
    }

    this.onShow = () => { };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  hoverIn(e) {
    const id = e.target.dataset.track;

    for (let i = 0; i < this._images.length; i++) {
      const image = this._images[i];
      if (image.dataset.track === id) {
        const limitX = Metrics.WIDTH / 8;
        const limitY = Metrics.HEIGHT / 8;
        const randX = Maths.maxminRandom(Metrics.WIDTH - limitX - image.offsetWidth, limitX);
        const randY = Maths.maxminRandom(Metrics.HEIGHT - limitY - image.offsetHeight, limitY);

        gsap.set(image, { left: randX, top: randY });
        image.classList.add('--active');

      } else {
        image.classList.remove('--active');
      }
    }
  }

  hoverOut() {
    for (let i = 0; i < this._images.length; i++) {
      this._images[i].classList.remove('--active');
    }
  }

  filter(e) {
    MusicController.stop();

    const btn = e.target;

    for (let i = 0; i < this._filters.length; i++) {
      const f = this._filters[i];
      if (f !== btn) f.classList.remove('--active');
      else f.classList.add('--active');
    }

    if (btn.dataset.categ === 'all') {
      for (let i = 0; i < this._tracks.length; i++) {
        const p = this._tracks[i];
        p.removeAttribute('disabled');
      }
    } else {
      const filter = `categ-${btn.dataset.categ}`;
      for (let i = 0; i < this._tracks.length; i++) {
        const p = this._tracks[i];
        if (p.classList.contains(filter)) {
          p.removeAttribute('disabled');
        } else {
          p.setAttribute('disabled', 'true');
        }
      }
    }
  }

  dispose() {
    MusicController.stop();

    for (let i = 0; i < this._filters.length; i++) {
      this._filters[i].removeEventListener(Basics.clickEvent, this._calls.click);
    }

    if (!isMobile) {
      for (let i = 0; i < this._tracks.length; i++) {
        const track = this._tracks[i];
        track.removeEventListener(Basics.mouseOver, this._calls.hoverIn);
        track.removeEventListener(Basics.mouseOut, this._calls.hoverOut);
      }
    }

    super.dispose();
  }
}

Scroll._registerClass('widget-tracks', ScrollItem__WidgetTracks);