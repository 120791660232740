import gsap from "gsap";

import { Basics, isTouch } from "../_app/cuchillo/core/Basics";
import { GetBy } from "../_app/cuchillo/core/Element";
import { Maths } from "../_app/cuchillo/utils/Maths";

export default class MusicController {
    static initialized = false;
    static tracks;
    static songs;
    static playBtn;
    static pauseBtn;
    static prevBtn;
    static nextBtn;
    static linkBtn;
    static currentSongIndex = -1;
    static isPlaying = false;
    static audioElement;
    static audioContext;
    // static audioElement = new Audio();
    // static audioContext = new AudioContext();
    static progressName;
    static progressThumb;
    static progressBar;
    static remainingTime;

    static ON_PLAY = 'onplay';
    static ON_STOP = 'onstop';

    static calls = {
        playSong: e => this.playSong(e.target.dataset.track),
        toggle: () => this.toggle(),
        next: () => this.next(),
        prev: () => this.prev(),
        progress: (e) => this.progress(e),
        down: (e) => this.down(e),
        drag: (e) => this.drag(e),
        up: (e) => this.up(e),
        visibility: (e) => this.visibility(e),
        cb: () => {
            this.audioElement.removeEventListener('ended', this.calls.cb);
            this.playLoop();
        }
    }

    static async init() {
        if (this.initialized) return;

        this.initialized = true;

        this.container = GetBy.id('music-player');
        if (!this.container) return;

        this.directHide();

        this.container.classList.add('--pause');

        this.songs = CMS_SONGS;

        this.audioElement = new Audio();
        this.audioElement.crossOrigin = 'anonymous';
        this.audioContext = new AudioContext();

        this.progressName = GetBy.id('ProgressName');
        this.remainingTime = GetBy.id('RemainingTime');

        this.progressThumb = GetBy.id('ProgressThumb');

        this.preloadSongs();
        this.addEvents();
    }

    static show() {
        gsap.to(this.container, {
            opacity: 1,
            duration: .3,
            onComplete: () => {
                this.container.style.pointerEvents = 'initial';
            }
        });
    }

    static hide() {
        this.container.style.pointerEvents = 'none';
        gsap.to(this.container, {
            opacity: 0,
            duration: .3
        });
    }

    static directHide() {
        this.container.style.pointerEvents = 'none';
        gsap.set(this.container, { opacity: 0 });
    }

    static visibility() {
        this.pause();
    }

    static addEvents() {
        this.playBtn = GetBy.selector('[data-play]', this.container)[0];
        this.pauseBtn = GetBy.selector('[data-pause]', this.container)[0];
        this.prevBtn = GetBy.selector('[data-prev]', this.container)[0];
        this.nextBtn = GetBy.selector('[data-next]', this.container)[0];
        this.linkBtn = GetBy.selector('[data-link]', this.container)[0];

        this.playBtn.addEventListener(Basics.clickEvent, this.calls.toggle);
        this.pauseBtn.addEventListener(Basics.clickEvent, this.calls.toggle);
        this.prevBtn.addEventListener(Basics.clickEvent, this.calls.prev);
        this.nextBtn.addEventListener(Basics.clickEvent, this.calls.next);

        this.progressBar = GetBy.id('ProgressTrack');
        this.progressBar.addEventListener(Basics.downEvent, this.calls.down, { passive: true });

        this.linkBtn.addEventListener(Basics.clickEvent, this.calls.visibility);
        // document.addEventListener("visibilitychange", this.calls.visibility);
    }

    static removeEvents() {
        this.playBtn.removeEventListener(Basics.clickEvent, this.calls.toggle);
        this.pauseBtn.removeEventListener(Basics.clickEvent, this.calls.toggle);
        this.prevBtn.removeEventListener(Basics.clickEvent, this.calls.prev);
        this.nextBtn.removeEventListener(Basics.clickEvent, this.calls.next);
        this.progressBar.removeEventListener(Basics.downEvent, this.calls.down);
        document.removeEventListener(Basics.moveEvent, this.calls.move);
        document.removeEventListener(Basics.upEvent, this.calls.up);

        this.linkBtn.removeEventListener(Basics.clickEvent, this.calls.visibility);
        // document.removeEventListener("visibilitychange", this.calls.visibility);
    }

    static mount() {
        this.tracks = [...GetBy.selector('[data-track]')];

        for (let i = 0; i < this.tracks.length; i++) {
            const track = this.tracks[i];
            track.addEventListener(Basics.clickEvent, this.calls.playSong);
        }
    }

    static unmount() {
        this.removeEvents();

        for (let i = 0; i < this.tracks.length; i++) {
            const track = this.tracks[i];
            track.removeEventListener(Basics.clickEvent, this.calls.playSong);
        }
        this.tracks = null;
    }

    static async preloadSongs() {
        for (const song of this.songs) {
            await this.loadSong(song.url);
        }
    }

    static async loadSong(url) {
        return new Promise((resolve, reject) => {
            const audio = new Audio();
            audio.src = url;
            audio.preload = 'auto';
            audio.addEventListener('canplaythrough', () => {
                audio.pause();
                resolve();
            });
            audio.addEventListener('error', (e) => {
                console.error('Error loading song: ', url, e);
                reject(e);
            });
        });
    }

    static play() {
        this.isPlaying = true;
        this.next();
        this.container.classList.add('--play');
        this.container.classList.remove('--pause');
        this.container.classList.remove('--stop');
    }

    static pause() {
        this.audioElement.pause();
        this.isPlaying = false;
        this.container.classList.remove('--play');
        this.container.classList.add('--pause');
    }

    static stop() {
        this.unselectSongs();

        this.audioElement.pause();
        this.isPlaying = false;
        this.progressName.innerHTML = '';
        this.linkBtn.href = '';
        this.currentSongIndex = -1;

        this.container.classList.remove('--play');
        this.container.classList.remove('--pause');
        this.container.classList.add('--stop');
    }

    static toggle() {
        if (this.isPlaying) {
            this.pause();
        } else {
            this.play();
        }
    }

    static down(event) {
        this.progress(event);

        document.addEventListener(Basics.moveEvent, this.calls.drag);
        document.addEventListener(Basics.upEvent, this.calls.up);
    }

    static up() {
        document.removeEventListener(Basics.moveEvent, this.calls.drag);
        document.removeEventListener(Basics.upEvent, this.calls.up);
        this.audioElement.play();
    }

    static drag(event) {
        this.progress(event);
    }

    static progress(event) {
        if (!this.isPlaying) return;

        const rect = this.progressBar.getBoundingClientRect();
        const left = isTouch ? event.touches[0].clientX : event.clientX;
        const offsetX = left - rect.left;
        const totalWidth = rect.width;
        const clickPercentage = Maths.clamp(offsetX / totalWidth, 0.01, 0.99);
        const newTime = this.audioElement.duration * clickPercentage;
        this.audioElement.currentTime = newTime;
    }

    static playLoop() {
        this.isPlaying = true;
        this.audioElement.currentTime = 0;
        this.audioElement.play();

        if (this.audioContext.state === 'suspended') {
            this.audioContext.resume();
        }
    }

    static next() {
        if (!this.isPlaying) this.play();

        if (this.currentSongIndex < this.songs.length - 1) {
            this.currentSongIndex++;
        } else this.currentSongIndex = 0;

        this.playCurrentSong();
    }

    static prev() {
        if (!this.isPlaying) this.play();

        if (this.currentSongIndex > 0) {
            this.currentSongIndex--;
        } else this.currentSongIndex = this.songs.length - 1;

        this.playCurrentSong();
    }

    static playSong(id) {
        for (let i = 0; i < this.tracks.length; i++) {
            const track = this.tracks[i];
            if (track.dataset.track === id) {
                this.currentSongIndex = i;
                break;
            }
        }

        if (this.isPlaying) {
            this.playCurrentSong();
        } else {
            this.currentSongIndex--;
            this.play();
        }
    }

    static playCurrentSong() {
        const track = this.tracks[this.currentSongIndex];
        const song = this.songs.filter(song => song.id === track.dataset.track)[0];
        this.audioElement.src = song.url;
        this.selectCurrentSong(song);

        this.audioElement.play();
        this.audioElement.addEventListener('ended', this.calls.cb);

        this.linkBtn.href = song.link;
        this.progressName.innerHTML = this.formatName(song.name);
        this.remainingTime.innerText = this.formatTime(this.audioElement.duration);

        if (this.audioContext.state === 'suspended') {
            this.audioContext.resume();
        }

        if (!this.audioElement.sourceNode) {
            const source = this.audioContext.createMediaElementSource(this.audioElement);
            this.audioElement.sourceNode = source;
            source.connect(this.audioContext.destination);
        }
    }

    static getProgress() {
        if (this.isPlaying) {
            return this.audioElement.currentTime / this.audioElement.duration;
        } else {
            return 0;
        }
    }

    static selectCurrentSong(song) {
        this.tracks.map(track => {
            if (track.dataset.track === song.id) {
                track.classList.add('--active');
            } else {
                track.classList.remove('--active');
            }
        });
    }

    static unselectSongs() {
        this.tracks.map(track => {
            track.classList.remove('--active');
        });
    }

    static formatTime(timeInSeconds) {
        if (isNaN(timeInSeconds)) timeInSeconds = 0;

        // const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = Math.floor(timeInSeconds % 60);

        // const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        // return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        return `${formattedMinutes}:${formattedSeconds}`;
    }

    static formatName(name) {
        return `${name + ' ' + ' ' + name + ' ' + name}<span class="aux">${name + ' ' + ' ' + name + ' ' + name}</span>`;
    }

    static loop() {
        if (!this.isPlaying) return;

        const remainingTime = this.audioElement.duration - this.audioElement.currentTime;
        this.remainingTime.innerText = this.formatTime(remainingTime);

        const progress = this.getProgress();
        gsap.set(this.progressThumb, { x: `${progress * 100}%` });
    }
}
