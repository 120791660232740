import gsap, { Power2 } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
gsap.registerPlugin(DrawSVGPlugin);

import { Basics } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';

class ScrollItem__WidgetLogo extends VScroll_Item {
  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    // this.logoVectors = GetBy.selector('.icon path', __link);
    // gsap.set(this.logoVectors, { drawSVG: '20%' });

    this.onShow = () => {
      // this.showLogo();
    };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================

  showLogo() {
    // for (let i = 0; i < this.logoVectors.length; i++) {
    //   const element = this.logoVectors[i];
    //   gsap.to(element, {
    //     drawSVG: '100%',
    //     duration: 1.5,
    //     delay: i * .25,
    //     ease: Power2.easeInOut
    //   });
    // }
  }

  dispose() {
    super.dispose();
  }
}

Scroll._registerClass('widget-logo', ScrollItem__WidgetLogo);